.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.remove_btn{
  width: 30px;
  border-radius: 50%;
}

.add_btn{
  width: 30px;
  border-radius: 50%;
}

.nav_styles_li{
  color:#282c34;
  padding-right: 15px;
}

.nav_left{
  float:left;
  padding-left: 10px;
  color:#71838E;
}

.nav_right{
  float:right;
  padding-right: 30px;
  cursor: pointer;
  color:#71838E;
  font-weight: bold;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

.nav_right:hover {
  color:#5f6568;
}

